import { AppThunk } from "../../models/app-thunk";
import * as types from "../../constants";
import { Action } from "../../models/action";
import { firestore } from "../../firebase/firebase";
import firebase from "firebase/app";
import { DialogAction } from "../../models/dialog-actions";
import { SnackState } from "../../models/snack-state";
import { openSnack } from "./uiActions";
import { cleanString } from "../../utils/utils";

export const getPlaces = (
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    console.log("getPlaces")
    try {
      const response = await firestore
        .collection("Recintos")
        .orderBy("Nombre")
        .limit(limit)
        .get();

      dispatch(setPlaces(mapPlaces(response)));
      dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const getPlacesList = (): AppThunk => {
  return async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await firestore
        .collection("Recintos")
        .orderBy("Nombre")
        .get();

      dispatch(setPlaces(mapPlaces(response)));
      dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const addPlace = (place: any): AppThunk => {
  return async (dispatch) => {
    dispatch(setPlaceDialogLoading(true));
    try {
      const response = await firestore.collection("Recintos").get();

      const __FOUND = response.docs.find(function (post: any, index: any) {
        if (cleanString(post.data().Nombre) === cleanString(place.Nombre)) {
          return true;
        }
      });

      if (__FOUND) {
        dispatch(openSnack("Recintos ya existe", SnackState.ERROR));
      } else {
        await firestore.collection("Recintos").doc().set(place);

        dispatch(getPlaces());
        dispatch(setSelectedPlace());
        dispatch(setDialogAction(DialogAction.Initial));
      }
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(setPlaceDialogLoading(false));
      dispatch(setPlaceDialogOpen(false));
    }
  };
};

export const updatePlace = (place: any): AppThunk => {
  return async (dispatch) => {
    dispatch(setPlaceDialogLoading(true));
    try {
      const response = await firestore.collection("Recintos").get();

      let placeID = "0";

      const __FOUND = response.docs.find(function (post: any, index: any) {
        if (cleanString(post.data().Nombre) === cleanString(place.Nombre)) {
          placeID = post.id;
          return true;
        }
      });

      if (__FOUND && placeID !== place.id) {
        dispatch(openSnack("Recintos ya existe", SnackState.ERROR));
      } else {
        const data = { ...place };
        delete data.id;
        await firestore.collection("Recintos").doc(place.id).update(data);

        dispatch(updatePlaceLocal(place));
      }
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(setPlaceDialogLoading(false));
      dispatch(setPlaceDialogOpen(false));
    }
  };
};

export const removePlace = (place: any): AppThunk => {
  return async (dispatch) => {
    dispatch(setPlaceDialogLoading(true));
    try {
      await firestore.collection("Recintos").doc(place.id).delete();

      dispatch(removePlaceLocal(place));
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(setPlaceDialogLoading(false));
      dispatch(setPlaceDialogOpen(false));
    }
  };
};

export const mapPlaces = (
  response: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
) => {
  let places: any[] = [];
  response.docs.forEach((doc) => {
    let data = doc.data();
    data.id = doc.id;
    places.push(data);
  });
  return places;
};

export const addMorePlaces = (
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(isLoading(true));

    const lastDoc = getState().placesReducer.lastDoc || "";

    try {
      const response = await firestore
        .collection("Recintos")
        .orderBy("Nombre")
        .startAfter(lastDoc)
        .limit(limit)
        .get();

      dispatch(addPlaces(mapPlaces(response)));
      dispatch(setLastDoc(response.docs[response.docs.length - 1]));
    } catch (error: any) {
      dispatch(setError(error));
      
    } finally {
      dispatch(isLoading(false));
    }
  };
};

export const getTotalDocs = (): AppThunk => {
  return async (dispatch) => {
    try {
      const response = await firestore.collection("Recintos").get();
      dispatch(setTotalDocs(response.size));
    } catch (error: any) {
      dispatch(setError(error));
      
    }
  };
};

export const openEditPlaceDialog = (place: any): AppThunk => {
  return (dispatch) => {
    dispatch(setDialogAction(DialogAction.Edit));
    dispatch(setSelectedPlace(place));
    dispatch(setPlaceDialogOpen(true));
  };
};

export const openNewPlaceDialog = (): AppThunk => {
  return (dispatch) => {
    dispatch(setDialogAction(DialogAction.New));
    dispatch(setPlaceDialogOpen(true));
  };
};

export const setPlaceDialogOpen = (isOpen: boolean): Action => ({
  type: types.PLACES_IS_DIALOG_OPEN,
  payload: isOpen,
});

const setPlaceDialogLoading = (isLoading: boolean): Action => ({
  type: types.PLACES_IS_DIALOG_LOADING,
  payload: isLoading,
});

export const setDialogAction = (dialogAction: DialogAction): Action => ({
  type: types.PLACES_CHANGE_DIALOG_ACTION,
  payload: dialogAction,
});

const updatePlaceLocal = (place: any): Action => ({
  type: types.PLACES_UPDATE_DOC,
  payload: place,
});

const removePlaceLocal = (place: any): Action => ({
  type: types.PLACES_REMOVE_DOC,
  payload: place,
});

export const setSelectedPlace = (setSelected?: any): Action => ({
  type: types.PLACES_SET_SELECTED_SECTOR,
  payload: setSelected,
});

const setPlaces = (places: any[]): Action => ({
  type: types.PLACES_GET_DOCS,
  payload: places,
});

const setLastDoc = (doc: any): Action => ({
  type: types.PLACES_SET_LAST_DOC,
  payload: doc,
});

const addPlaces = (places: any[]): Action => ({
  type: types.PLACES_ADD_DOCS,
  payload: places,
});

const isLoading = (isloading: boolean): Action => ({
  type: types.PLACES_LOADING,
  payload: isloading,
});

const setError = (error: string): Action => ({
  type: types.PLACES_FAILURE,
  payload: error,
});

const setTotalDocs = (total: number): Action => ({
  type: types.PLACES_SET_TOTAL_DOCS,
  payload: total,
});
