import {
	SimpleCrud,
	SimpleCrudProps,
} from "src/components/SimpleCrud";
import { useBusiness } from "src/hooks/redux/useBusiness";
import {
	actualizarRecintoEmpresa,
	agregarRecintoEmpresa,
	quitarRecintoEmpresa,
} from "src/redux/actions/businessActions";
import { useDispatch } from "src/redux/store";

export const BusinessPrecinctsTab = () => {
	const dispatch = useDispatch();
	const { selectedBusiness, loading } = useBusiness();

	const handleSubmit: SimpleCrudProps["onCreateOrUpdate"] =
		(values, action, selected) => {
			if (action === "New")
				dispatch(agregarRecintoEmpresa(values));
			else if (action === "Edit")
				dispatch(
					actualizarRecintoEmpresa(selected.Nombre, values)
				);
		};

	const handleDelete: SimpleCrudProps["onDelete"] = (
		selected
	) => dispatch(quitarRecintoEmpresa(selected.Nombre));

	return (
		<SimpleCrud
			key={"Recintos"}
			array={[...new Set(selectedBusiness?.Recintos)]}
			isLoading={loading}
			name="Recinto"
			onCreateOrUpdate={handleSubmit}
			onDelete={handleDelete}
		/>
	);
};
