import { useFormik } from "formik";
import React, {
	useCallback,
	useEffect,
	useState,
} from "react";
import * as yup from "yup";
import { SnackState } from "src/models/snack-state";
import { openSnack } from "src/redux/actions/uiActions";
import {
	AnyProject,
	getProjectName,
	isChecklist,
	isRevision,
	isChecklistRevision,
	Checklist,
} from "src/models/Proyecto";
import { useDispatch, useSelector } from "src/redux/store";
import { useBusiness } from "src/hooks/redux/useBusiness";
import {
	setGettingProjectsUserIn,
	setProjectsUser,
} from "src/redux/actions/projectsActions";
import {
	createUser,
	updateUser,
} from "src/redux/actions/usersActions";
import naturalSort from "natural-sort";

export type UserValidator = ReturnType<
	typeof useUserValidator
>;

export const useUserValidator = (
	onClose: VoidFunction,
	onRefresh?: () => void
) => {
	const dispatch = useDispatch();

	const { selectedBusiness } = useBusiness();
	const {
		users,
		edit: { selectedUser },
	} = useSelector((state) => state.usersReducer);

	const { allProjects, isLoading } = useSelector(
		(state) => state.projectsReducer
	);

	const directorsCount = (
		users?.filter(
			(x) => x.TipoUsuario === "AdministradorEmpresa"
		) ?? []
	).length;

	const validateAvailableUsers = (data: any) =>
		data.UsuariosUsados < data.LimiteUsuarios;

	// Initial form values
	const {
		Nombre = "",
		Apellido = "",
		Email = "",
		Telefono = "+569",
		Activado = true,
		Cargo = "",
		Revisiones = false,
		Checklist = false,
		ChecklistsOT = false,
		TipoUsuario = "UsuarioEmpresa",
	} = selectedUser ?? {};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			Nombre,
			Apellido,
			Email,
			Telefono,
			Activado,
			Cargo,
			Revisiones,
			Checklist,
			ChecklistsOT,
			TipoUsuario,
			VerifyEmail: "",
		},
		onSubmit: async ({ VerifyEmail, ...values }) => {
			try {
				if (!(values.Revisiones || values.Checklist || values.ChecklistsOT)) {
					return dispatch(openSnack("Debes seleccionar al menos un módulo.", SnackState.ERROR));
				}

				dispatch(setGettingProjectsUserIn("Initial"));
				dispatch(setProjectsUser([]));

				if (selectedUser) {
					await dispatch(updateUser(values, projectsToAllocate, projectsToDeallocate));
				} else {
					await dispatch(createUser(values, projectsToAllocate));
				}

				handleReset();
				onClose();
				onRefresh?.();
				
			} catch (error) {
				console.error('Error saving user:', error);
			}
		},

		validationSchema: yup.object({
			Nombre: yup.string().required("Nombre requerido"),
			Apellido: yup
				.string()
				.required("Apellido es requerido"),
			Telefono: yup
				.string()
				.required("Número es requerido"),
			Email: yup
				.string()
				.email("Requiere correo válido")
				.required("Email es requerido"),
			VerifyEmail: selectedUser
				? yup.string().notRequired()
				: yup
					.string()
					.email("Email no válido")
					.max(255)
					.oneOf(
						[yup.ref("Email"), null],
						"Email no coincide"
					)
					.required("Este campo es requerido"),
			TipoUsuario: yup
				.string()
				.required("Tipo usuario es requerido"),
			Cargo: yup.string().required("Cargo es requerido"),
		}),
	});

	const handleReset = () => {
		formik.resetForm();
		setProjectsToDeallocate([]);
		setProjectsToAllocate([]);
		onClose();
	};

	/**
	 * Nueva lógica de selección
	 */
	useEffect(() => {
		if (selectedUser) {
			setProjectsToAllocate(
				allProjects.filter((project) =>
					project.Responsables.find((id) => {
						if (typeof id === "string") {
							return id === selectedUser.id;
						} else {
							return id.id === selectedUser.id;
						}
					})
				)
			);
		}
	}, [selectedUser, allProjects]);

	const [projectsToDeallocate, setProjectsToDeallocate] =
		useState<AnyProject[]>([]);

	const [projectsToAllocate, setProjectsToAllocate] =
		useState<AnyProject[]>([]);

	const available = React.useMemo(() => {
		const all = allProjects.filter(
			(p1) =>
				!projectsToAllocate.find((p2) => p1.id === p2.id)
		);

		return {
			revisions: all
				.filter(isRevision)
				.sort((a, b) =>
					naturalSort()(a.NombreProyecto, b.NombreProyecto)
				),
			checklistRevisions: all
				.filter(isChecklistRevision)
				.sort((a, b) =>
					naturalSort()(a.NombreProyecto, b.NombreProyecto)
				),
			checklists: all
				.filter(isChecklist)
				.sort((a, b) => naturalSort()(a.Nombre, b.Nombre)),
		};
	}, [allProjects, projectsToAllocate]);

	const picked = React.useMemo(() => {
		const byName = (a: AnyProject, b: AnyProject) =>
			getProjectName(a).localeCompare(getProjectName(b));

		return {
			revisions: projectsToAllocate
				.filter(isRevision)
				.sort(byName),
			checklistRevisions: projectsToAllocate
				.filter(isChecklistRevision)
				.sort(byName),
			checklists: projectsToAllocate
				.filter(isChecklist)
				.sort(byName),
		};
	}, [projectsToAllocate]);

	const allocateProject = (project: AnyProject) => {
		setProjectsToAllocate([...projectsToAllocate, project]);

		if (
			projectsToDeallocate.find((w) => w.id === project.id)
		) {
			setProjectsToDeallocate(
				projectsToDeallocate.filter(
					(r) => r.id !== project.id
				)
			);
		}
	};

	const deallocateProject = (project: AnyProject) => {
		setProjectsToAllocate(
			projectsToAllocate.filter((r) => r.id !== project.id)
		);
		if (
			allProjects.some(
				(_project) => _project.id === project.id
			)
		)
			setProjectsToDeallocate([
				...projectsToDeallocate,
				project,
			]);
	};

	const getChecklistResponsible = useCallback(
		(project: Checklist) => {
			const { Responsable } = project;
			return users?.find(
				(user) => user.id === Responsable.id
			);
		},
		[users]
	);

	const getAutocompleteLabel = useCallback(
		(project: AnyProject) => {
			if (isChecklist(project)) {
				const responsible =
					getChecklistResponsible(project);
				const { Nombre, Apellido, Cargo } =
				responsible ?? {};
				return `${project.Nombre} - ${
					responsible
						? `${Nombre} ${Apellido} (${Cargo})`
						: "Sin responsable"
				}`;
			} else {
				const { Responsable, NombreProyecto } = project;
				const { Cargo, NombreCompleto } = Responsable;
				return `${NombreProyecto} - ${NombreCompleto} (${Cargo})`;
			}
		},
		[getChecklistResponsible]
	);

	return {
		formik,
		selectedBusiness,
		directorsCount,
		validateAvailableUsers,
		isLoading,
		picked,
		available,
		projectsToAllocate,
		projectsToDeallocate,
		setProjectsToAllocate,
		setProjectsToDeallocate,
		handleReset,
		selectedUser,
		allocateProject,
		deallocateProject,
		getChecklistResponsible,
		getAutocompleteLabel,
	};
};
