import { Dialog, DialogContent } from "@material-ui/core";
import { FC, useEffect } from "react";
import { DialogTitle } from "src/components/CheckPro/DialogTitle";
import { TabGroup } from "src/components/CheckPro/TabGroup";
import { useUserValidator } from "./validator";
import { UserModules } from "./Modules";
import { UserData } from "./Data";
import { UserAuthPanel } from "./Auth";
import { useDispatch } from "react-redux";
import {getProjectsUserIn, setGettingProjectsUserIn, setProjectsUser} from "src/redux/actions/projectsActions";

interface Props {
	open: boolean;
	onClose: () => void;
	onRefresh?: () => void;
}

export const DialogCreateEditUser: FC<Props> = ({
													open,
													onClose,
													onRefresh
												}) => {
	const validator = useUserValidator(onClose, onRefresh);
	const dispatch = useDispatch();

	// Añadir efecto para recargar datos cuando se abre el modal
	useEffect(() => {
		if (open && validator.selectedUser?.id) {
			dispatch(getProjectsUserIn(validator.selectedUser.id));
		}
		
		return () => {
			// Solo limpiar el estado
			dispatch(setProjectsUser([]));
			dispatch(setGettingProjectsUserIn("Initial"));
		};
	}, [open, validator.selectedUser]);

	const { selectedUser } = validator;

	const {
		Nombre = "Crear",
		Apellido = "usuario",
		Email = "",
	} = selectedUser ?? {};

	return (
		<Dialog
			open={open}
			onClose={(_, reason) => {
				if (reason === "backdropClick") return;
				onClose();
			}}
		>
			<DialogTitle
				onClose={onClose}
				bigText={`${Nombre} ${Apellido}`}
				smallText={
					selectedUser ? Email : "Completa el formulario"
				}
			/>
			<DialogContent style={{ minHeight: 300 }}>
				<form onSubmit={validator.formik.handleSubmit}>
					<TabGroup
						tabs={["Autenticación", "Datos", "Módulos"]}
						disabled={[!selectedUser]}
						hidden={[!selectedUser]}
						defaultSelected={selectedUser ? 0 : 1}
					>
						<UserAuthPanel
							{...validator}
							onClose={onClose}
						/>

						<UserData {...validator} />

						<UserModules {...validator} />
					</TabGroup>
				</form>
			</DialogContent>
		</Dialog>
	);
};
