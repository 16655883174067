import { Card, Grid, Typography } from "@material-ui/core";
import { useState } from "react";
import { ChevronRight } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { SimpleCrud } from "src/components/SimpleCrud";
import { Subcontrato } from "src/models/Empresa";
import {
	agregarSubcontratoEmpresa,
	agregarErrorTipoSubcontrato,
	quitarSubcontratoEmpresa,
	eliminarErrorTipoSubcontrato,
	actualizarSubcontratoEmpresa,
	actualizarErrorTipoSubcontrato,
} from "src/redux/actions/businessActions";
import { IBusinessState } from "src/redux/reducers/businessReducer";
import { RootState } from "src/redux/store/reducer";

export const BusinessSubcontractTab = () => {
	const dispatch = useDispatch();
	const {
		edit: { selectedBusiness },
		typeErrors: { isLoading: typeErrorsLoading },
		subContracts: { isLoading: subContractsIsLoading },
	} = useSelector<RootState, IBusinessState>(
		(state) => state.businessReducer
	);

	const [selectedSubContract, setSelectedSubContract] =
		useState<Subcontrato | undefined>();

	return (
		<Grid container spacing={4}>
			<Grid item xs={6}>
				<SimpleCrud
					key={"SubContratos"}
					array={[
						...new Set(selectedBusiness?.Subcontratos),
					]}
					isLoading={subContractsIsLoading}
					name="Subcontrato"
					onCreateOrUpdate={(
						values: Subcontrato,
						action,
						selected: Subcontrato
					) => {
						if (action === "New") {
							dispatch(agregarSubcontratoEmpresa(values));
						} else if (action === "Edit") {
							dispatch(
								actualizarSubcontratoEmpresa(
									selected.Nombre,
									{
										...selected,
										...values,
									}
								)
							);
						}
						// @ts-ignore
					}}
					// @ts-ignore
					onDelete={(selected: Subcontrato) => {
						dispatch(
							quitarSubcontratoEmpresa(selected.Nombre)
						);
					}}
					cardTitle={"Subcontratos"}
					extraActions={[
						{
							tooltipText: "Errores Tipo",
							icon: <ChevronRight />,
							handleAction: setSelectedSubContract,
						},
					]}
				/>
			</Grid>
			{/* Errores tipo */}
			<Grid item xs={6}>
				{selectedSubContract ? (
					<SimpleCrud
						key={"ErroresTipo"}
						// @ts-ignore
						array={[
							...new Set(
								selectedSubContract?.ErroresTipo || []
							),
						]}
						isLoading={typeErrorsLoading}
						name="ErroresTipo"
						onCreateOrUpdate={(
							values,
							action,
							selected
						) => {
							if (action === "New") {
								dispatch(
									agregarErrorTipoSubcontrato(
										selectedSubContract.Nombre,
										values,
										setSelectedSubContract
									)
								);
							} else if (action === "Edit") {
								dispatch(
									actualizarErrorTipoSubcontrato(
										selectedSubContract.Nombre,
										// @ts-ignore
										selected.Nombre,
										values,
										setSelectedSubContract
									)
								);
							}
						}}
						onDelete={(selected) => {
							dispatch(
								eliminarErrorTipoSubcontrato(
									selectedSubContract.Nombre,
									selected.Nombre,
									setSelectedSubContract
								)
							);
						}}
						cardTitle={
							"Errores Tipo - " +
							selectedSubContract?.Nombre
						}
					/>
				) : (
					<Card style={{ minHeight: 250 }}>
						<Grid
							container
							direction="column"
							alignItems="center"
							justify="center"
						>
							<Grid item>
								<img
									style={{
										marginTop: 20,
										marginBottom: 20,
									}}
									alt="Imagen vacía"
									src="/static/void.svg"
									height="150"
								/>
							</Grid>
							<Grid item>
								<Typography variant="h5">
									Ningún sub-contrato seleccionado
								</Typography>
							</Grid>
						</Grid>
					</Card>
				)}
			</Grid>
		</Grid>
	);
};
