import { createAsyncThunk } from "@reduxjs/toolkit";
import {
	HojaObraGruesa,
	HojaTerminaciones,
	SheetOuterJoinOT,
	SheetOT,
} from "src/models/OT/Sheet";
import { getRef } from "src/utils/firebase";
import Firebase from "firebase";
import { RootState } from "src/redux/store/reducer";
import { createInitialReviewerResponse } from "../utils/sheet";
import { otActions } from "../slice";
import { openSnack } from "src/redux/actions/uiActions";
import { SnackState } from "src/models/snack-state";
import { firestore } from "src/firebase/firebase";
import { FirebaseListener } from "src/utils/classes/FirebaseListeners";
import { Usuario } from "src/models/Usuario";
import { setUsers } from "src/redux/actions/usersActions";

const listener = FirebaseListener.addListener("sheets-ot");

export const getPlaceSheetsOT = createAsyncThunk<SheetOT[]>(
	"checklists-ot/checklists/places/sheets/get",
	async (_, { dispatch, getState }) => {
		const place = (getState() as RootState)
			.neoChecklistReducer.selected.assignedPlace!;
			console.log("place",place)
		const sheetsRef =
			getRef(place).collection("RespuestasOT");

		const query = sheetsRef.orderBy(
			"FechaCreacion",
			"desc"
		);

		listener.close();

		return new Promise<SheetOT[]>((resolve, reject) => {
			query.get().then(
				(snap) => {
					const sheets = snap.docs.map((d, i, arr) => {
						const sheet = {
							...d.data(),
							_ref: d.ref,
							_position: arr.length - i,
						} as SheetOT;

						const selectedId = (getState() as RootState)
							.neoChecklistReducer.selected.sheet?._ref?.id;

						if (selectedId === d.id)
							dispatch(
								otActions.assignSelected({ sheet: sheet })
							);

						return sheet;
					});

					console.log("respondio", sheets);
					dispatch(
						otActions.assignChecklist({ responses: sheets })
					);
					resolve(sheets);
				},
				(error) => {
					console.error(error);
					dispatch(
						openSnack(
							error.message,
							SnackState.ERROR
						) as any
					);
					reject(error);
				}
			);
		});
	}
);

type SheetCreator = {
	type: SheetOT["Tipo"];
	initialValues: SheetOuterJoinOT & {
		Unidades: number;
	};
};

export const addSheetToProjectSpaceOT = createAsyncThunk(
	"checklists-ot/checklists/places/sheets/add",
	async (
		{ type, initialValues }: SheetCreator,
		{ getState, dispatch }
	) => {
		try {
			const place = (getState() as RootState)
				.neoChecklistReducer.selected.assignedPlace!;
			const checklist = (getState() as RootState)
				.neoChecklistReducer.selected.checklist!;
			const placeRef = getRef(place);
			const sheetRef = placeRef
				.collection("RespuestasOT")
				.doc();

			const reviewerSnaps = await Promise.all(
				place.Revisores.filter((r) => r !== null).map((r) =>
					r!.get()
				)
			);

			const reviewers = reviewerSnaps.map((snap) => {
				if (!snap.exists) {
					throw Error(
						"Uno de los revisores asignados fue eliminado. Por favor, cámbialo."
					);
				}

				return {
					...snap.data(),
					_ref: snap.ref,
				} as Usuario;
			});

			const sheetData: Omit<
				HojaObraGruesa | HojaTerminaciones,
				"FechaCreacion"
			> = {
				PreguntasMalas: 0,
				Estado: "En Proceso",
				Tipo: type,
				Unidades: initialValues.Unidades,
				Revisores: reviewers.map((r) =>
					createInitialReviewerResponse(
						r,
						checklist.Preguntas
					)
				),
				EspacioRef: placeRef,
				NombreEspacio: place.Nombre,
				ChecklistRef: placeRef.parent.parent!,
				NombreChecklist: checklist.Nombre,
				ProyectoRef: placeRef.parent.parent!.parent.parent!,
			};

			if (type === "Obra Gruesa") {
				const { PlanoRevisor, Elemento, Ubicacion } =
					initialValues as HojaObraGruesa;
				(sheetData as HojaObraGruesa).PlanoRevisor =
					PlanoRevisor;
				(sheetData as HojaObraGruesa).Elemento = Elemento;
				(sheetData as HojaObraGruesa).Ubicacion = Ubicacion;
			} else if (type === "Terminaciones") {
				const { Sector, Recinto, Pasillo, Otro } =
					initialValues as HojaTerminaciones;
				(sheetData as HojaTerminaciones).Recinto = Recinto;
				(sheetData as HojaTerminaciones).Sector = Sector;
				(sheetData as HojaTerminaciones).Pasillo = Pasillo;
				(sheetData as HojaTerminaciones).Otro = Otro;
			} else
				throw Error("El tipo de la respuesta es inválido.");

			const batch = firestore.batch();

			batch.set(sheetRef, {
				...sheetData,
				FechaCreacion: Firebase.firestore.Timestamp.now(),
			});

			batch.update(placeRef, { Contado_: false });

			await batch.commit();

			dispatch(
				openSnack(
					"Hemos agregado la hoja de respuestas.",
					SnackState.SUCCESS
				) as any
			);
		} catch (error: any) {
			console.error(error);
			dispatch(
				openSnack(error.message, SnackState.ERROR) as any
			);
		}
	}
);

export const removeSheetFromProjectSpaceOT =
	createAsyncThunk(
		"checklists-ot/checklists/places/sheets/remove",
		async (sheet: SheetOT, { dispatch }) => {
			try {
				const images: string[] = [];
				const sheetRef = getRef(sheet);
				const placeRef = sheetRef.parent.parent!;

				const { Revisores } = sheet;

				Revisores.forEach((revisor) => {
					const { Firma, Categorias } = revisor;

					Firma && images.push(Firma.Uri);

					Categorias.flatMap(
						(category) => category.Preguntas
					)
						.flatMap((question) => question.Respuestas)
						.flatMap((response) => response?.Foto)
						.forEach(
							(photo) => photo && images.push(photo.Uri)
						);
				});

				const batch = firestore.batch();

				batch.set(
					firestore.collection("ImagesToDelete").doc(),
					{ images }
				);

				batch.delete(sheetRef);

				batch.update(placeRef, {
					Contado_: false,
				});

				await batch.commit();

				dispatch(
					openSnack(
						"Hemos eliminado la hoja de respuestas.",
						SnackState.SUCCESS
					) as any
				);
			} catch (error: any) {
				console.error(error);
				dispatch(
					openSnack(error.message, SnackState.ERROR) as any
				);
			}
		}
	);

export const getSheetUsers = createAsyncThunk(
	"checklists-ot/checklists/places/sheets/users/get",
	async (_, { dispatch, getState }) => {
		try {
			const sheets = (getState() as RootState)
				.neoChecklistReducer.checklist.responses;

			if (!sheets) return;

			const unique: Record<
				string,
				Firebase.firestore.DocumentReference
			> = {};

			sheets.forEach((sheet) => {
				const { Revisores } = sheet;

				Revisores.forEach((revisor) => {
					const { Revisor } = revisor;
					unique[Revisor.id] = Revisor;
				});
			});

			console.log(Object.values(unique).length);

			const users = await Promise.all(
				Object.values(unique).map(async (ref) => {
					const snap = await ref.get();
					return {
						...snap.data(),
						_ref: ref,
						id: ref.id,
					} as Usuario;
				})
			);

			dispatch(setUsers(users));
		} catch (error: any) {
			console.error(error);
			dispatch(
				openSnack(error.message, SnackState.ERROR) as any
			);
		}
	}
);