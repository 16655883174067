import { useMemo, useState } from "react";
import {
	Box,
	IconButton,
	Tooltip,
	Typography,
	CircularProgress,
} from "@material-ui/core";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";
import { PercentageCell } from "../Misc/PercentageCell";
import { ChevronRight, Edit, Trash2 } from "react-feather";
import { NeoPlaceAssignDialog } from "./Dialog/NeoPlaceAssign";
import { useHistory } from "react-router-dom";
import { getRef } from "src/utils/firebase";
import { DropdownMenu } from "../../Generic/DropdownMenu";
import { Opener } from "../../CheckPro/Opener";
import { colorsCheckPro } from "src/theme/variants";
import naturalSort from "natural-sort";
import { NeoPlaceDisableDialog } from "./Dialog/NeoPlaceDisable";
import { AssignedPlaceOT } from "src/models/OT/Place";
import { Table } from "src/utils/components/Table";
import { getSignaturesSummaryPdfBlob } from 'src/redux/neo-checklists/actions/reports';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from "src/redux/store";
import {getPlaceSheetsOT} from "../../../redux/neo-checklists/actions/sheets";
import {PictureAsPdfTwoTone} from "@material-ui/icons";

export const NeoPlacesTable = () => {
	const history = useHistory();
	// @ts-ignore
	const dispatch = useDispatch<AppDispatch>();
	const user = useSelector((s) => s.authReducer.user);

	const [dialog, setDialog] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const { selected, checklist, set, toggle } =
		useProjectsOT();

	const dataToShow = useMemo(() => {
		if (!checklist.places) return [];
		return checklist.places
			.filter((p) => p.Asignado)
			.sort((a, b) => naturalSort()(a.Nombre, b.Nombre));
	}, [checklist.places]);

	const calculateProgress = (ofPlace: AssignedPlaceOT) => {
		const { Cantidad, CantidadCompletada = 0 } = ofPlace;

		const percentage =
			(CantidadCompletada / Cantidad) * 100;

		if (isNaN(percentage)) return 0;
		return percentage;
	};

	const handleEdit = (place: AssignedPlaceOT) => {
		toggle.assignedPlace([place]);
		setDialog(1);
	};

	const handleDelete = (place: AssignedPlaceOT) => {
		toggle.assignedPlace([place]);
		setDialog(2);
	};

	const handleRedirectToAnswers = (
		ofPlace: AssignedPlaceOT
	) => {
		const placeRef = getRef(ofPlace);
		const checklistRef = getRef(selected.checklist);

		set.assignedPlace(ofPlace);
		history.push(`${checklistRef.id}/${placeRef.id}`);
	};

	const handleDownloadResponses = async (place: AssignedPlaceOT) => {
		try {
			console.log(place)
			setIsLoading(true);
			set.assignedPlace(place);
			const sheets = await dispatch(getPlaceSheetsOT()).unwrap();

			if (!sheets) {
				console.log("No hay respuestas para descargar");
				return;
			}

			const zip = new JSZip();

			for (const sheet of sheets) {
				try {
					const pdfBlob = await dispatch(getSignaturesSummaryPdfBlob({
						responsePath: getRef(sheet).path,
						// @ts-ignore
						responseNumber: sheet._position,
					})).unwrap();

					if (pdfBlob instanceof Blob) {
						zip.file(`${sheet.NombreChecklist}_${sheet.NombreEspacio}_${sheet._position}.pdf`, pdfBlob);
					} else {
						console.error(`El PDF para la hoja ${sheet._position} no es un Blob válido`);
					}
				} catch (error) {
					console.error(`Error al obtener el PDF para la hoja ${sheet._position}:`, error);
				}
			}

			if (zip.files && Object.keys(zip.files).length > 0) {
				const zipBlob = await zip.generateAsync({type: "blob"});
				saveAs(zipBlob, `${place.Nombre}_respuestas.zip`);
				console.log("Respuestas descargadas exitosamente");
			} else {
				console.log("No se pudo generar ningún PDF para descargar");
			}
		} catch (error) {
			console.error("Error al descargar respuestas:", error);
		} finally {
			setIsLoading(false);
		}
	};
	return (
		<>
			<Table
				stripped
				elementsProps={{
					TableCellProps: { style: { padding: 5 } },
				}}
				disableExporting
				loading={!checklist.places}
				list={dataToShow}
				emptyMessage="Debe asignar espacios a la lista de chequeo"
				columns={[
					{
						header: "Nombre",
						Cell: ({ value }) => <>{value.Nombre}</>,
					},
					{
						align: "center",
						header: "Cantidad",
						Cell: ({ value }) => (
							<>
								{value.CantidadCompletada ?? 0} /{" "}
								{value.Cantidad}
							</>
						),
					},
					{
						align: "center",
						header: "Unidad",
						Cell: () => (
							<>{selected.checklist?.Unidad ?? "Unidad"}</>
						),
					},
					{
						align: "center",
						header: "Progreso",
						Cell: ({ value }) => (
							<Tooltip
								title={
									<Typography>
										<ul>
											<li>Menor o igual del 25%: rojo </li>
											<li>
												Sobre 25% y menos 50%: naranjo
											</li>
											<li>
												Sobre 50% y menos al 75%: amarillo
											</li>
											<li>Sobre 75% y menos a 99%: azul</li>
											<li>Sobre el 99%: verde</li>
										</ul>
										Si la meta es 50%, y has completado un
										51%, se vería en verde porque superaría
										el 99% RESPECTO A LA META.
									</Typography>
								}
							>
								<Box>
									<PercentageCell
										value={calculateProgress(value)}
										aim={selected.checklist?.MetaRevision}
									/>
								</Box>
							</Tooltip>
						),
					},
					{
						align: "right",
						Cell: ({ value }) => (
							<>
								{user?.tipoUsuario !== "UsuarioEmpresa" && !selected.project?.Eliminado && (
										<DropdownMenu
											Opener={Opener}
											options={[
												{
													label: "Editar",
													onClick: () => handleEdit(value),
													icon: (
														<Edit
															color={colorsCheckPro.primary}
														/>
													),
												},
												{
													label: "Eliminar",
													onClick: () =>
														handleDelete(value),
													icon: <Trash2 color="red" />,
												},
												{
													label: "Respuestas y firmas",
													icon:
														<PictureAsPdfTwoTone
															htmlColor={  "red" }
														/>,
													onClick: () => handleDownloadResponses(value),
												},
											]}
										/>
									)}

								{user?.tipoUsuario === "UsuarioEmpresa" && !selected.project?.Eliminado && (
									<DropdownMenu
										Opener={Opener}
										options={[
											{
												label: "Respuestas y firmas",
												icon:
													<PictureAsPdfTwoTone
														htmlColor={  "red" }
													/>,
												onClick: () => handleDownloadResponses(value),
											},
										]}
									/>
								)}

								<Tooltip title="Ver respuestas">
									<IconButton
										size="small"
										color="primary"
										onClick={() =>
											handleRedirectToAnswers(value)
										}
									>
										<ChevronRight />
									</IconButton>
								</Tooltip>
							</>
						),
					},
				]}
			/>

			<NeoPlaceAssignDialog
				mode="edit"
				open={dialog === 1}
				onClose={() => setDialog(0)}
			/>

			<NeoPlaceDisableDialog
				open={dialog === 2}
				onClose={() => setDialog(0)}
			/>

			{isLoading && (
				<Box
					position="fixed"
					top={0}
					left={0}
					width="100%"
					height="100%"
					display="flex"
					alignItems="center"
					justifyContent="center"
					bgcolor="rgba(0, 0, 0, 0.5)"
					zIndex={9999}
				>
					<CircularProgress />
				</Box>
			)}
		</>
	);
};