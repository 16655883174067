import { Action } from "../../models/action";
import * as types from "../../constants";
import { Usuario } from "src/models/Usuario";

export interface IUsersState {
	users?: Usuario[];
	isLoading: boolean;
	totalDocs: number;
	lastDoc?: any;
	error?: string;
	edit: {
		isLoading: boolean;
		selectedUser?: Usuario | null;
		error?: any;
	};
}

const initialState = {
	users: [],
	isLoading: false,
	totalDocs: 0,
	edit: {
		isLoading: false,
	},
} as IUsersState;

export const usersReducer = (
	state = initialState,
	action: Action
): IUsersState => {
	switch (action.type) {
		case types.USERS_GET_DOCS:
			return {
				...state,
				users: action.payload,
			};
		case types.USERS_SET_SELECTED:
			console.log(types.USERS_SET_SELECTED)
			return {
				...state,
				edit: {
					...state.edit,
					selectedUser: action.payload,
				},
			};
		case types.USERS_UPDATE_SELECTED_USER:
			return {
				...state,
				edit: {
					...state.edit,
					selectedUser: action.payload,
				},
			};
		case types.USERS_EDIT_LOADING:
			return {
				...state,
				edit: {
					...state.edit,
					isLoading: action.payload,
				},
			};
		case types.USERS_ADD_DOCS:
			return {
				...state,
				users: [...(state.users || []), ...action.payload],
			};
		case types.USERS_UPDATE_DOC:
			return {
				...state,
				users: state.users?.map((x) =>
					x.id === action.payload.id
						? { ...x, ...action.payload }
						: x
				),
			};
		case types.USERS_REMOVE_DOC:
			return {
				...state,
				users: state.users?.filter(
					(x) => x.id !== action.payload.id
				),
			};
		case types.USERS_SET_LAST_DOC:
			return {
				...state,
				lastDoc: action.payload,
			};
		case types.USERS_SET_TOTAL_DOCS:
			return {
				...state,
				totalDocs: action.payload,
			};
		case types.USERS_LOADING:
			return {
				...state,
				isLoading: action.payload,
			};
		case types.USERS_FAILURE:
			return {
				...state,
				error: action.payload,
			};
		case types.USERS_TABLES_UPDATE_SUBMITTING:
			return {
				...state,
				edit: {
					isLoading: true,
					error: undefined,
				},
			};
		case types.USERS_TABLES_UPDATE_SUCCESS:
			return {
				...state,
				edit: {
					isLoading: false,
				},
			};
		case types.USERS_TABLES_UPDATE_FAILURE:
			return {
				...state,
				edit: {
					isLoading: false,
					error: action.payload,
				},
			};
		default:
			return state;
	}
};
