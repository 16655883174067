/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Skeleton } from "@material-ui/lab";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";
import { getProjectChecklistsWithResponses } from "src/redux/actions/projectsActions";
import { useDispatch } from "react-redux";
import { SheetOT } from "src/models/OT/Sheet";
import { otActions } from "src/redux/neo-checklists/slice";
import { PlaceOT } from "src/models/OT/Place";
import { ChecklistOT } from "src/models/OT/Checklist";

// Definir interfaces para la respuesta
interface PlaceWithResponses {
	place: PlaceOT;
	responses: SheetOT[];
	cantidad: number;
}

interface ChecklistWithResponses {
	checklist: ChecklistOT;
	placesWithResponses: PlaceWithResponses[];
}

// Primero, definir una interfaz para los totales
interface ChecklistTotal {
	name: string;
	totalUnits: number;
	totalCantidad: number;
}

export const RevisionGoalGraphOT = () => {
	const { checklist, project, selected } = useProjectsOT();
	// @ts-ignore
	const dispatch = useDispatch<AppDispatch>();
	const [pendingSheets, setPendingSheets] = useState<ChecklistTotal[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [hasLoaded, setHasLoaded] = useState(false);

	// Primer useEffect para debug
	useEffect(() => {
		console.log('Estado actual:', {
			hasProject: !!selected.project,
				projectId: selected.project?._ref?.id,
				isLoading
		});
	}, [selected.project, isLoading]);

	useEffect(() => {
		const fetchData = async () => {
			if (!selected.project?._ref?.id || hasLoaded) {
				console.log('No hay proyecto seleccionado o ya se cargaron los datos');
				return;
			}

			if (isLoading) {
				console.log('Ya hay una carga en proceso');
				return;
			}

			try {
				setIsLoading(true);
				console.log('1. Iniciando obtención de datos para proyecto:', selected.project._ref.id);

				const checklistsData = await dispatch(
					getProjectChecklistsWithResponses(selected.project._ref.id)
				) as unknown as ChecklistWithResponses[];

				console.log('2. Datos obtenidos:', checklistsData);

				// Procesar todas las respuestas
				let sheets: SheetOT[] = [];
				let inProcessSheets: ChecklistTotal[] = [];
				
				checklistsData.forEach(({ placesWithResponses, checklist }) => {
					const checklistTotals = placesWithResponses.reduce((acc, { responses, cantidad }) => {
						sheets = [...sheets, ...responses];
						
						const pendingUnits = responses
							.filter(sheet => sheet.Estado === "En Proceso")
							.reduce((sum, sheet) => sum + (sheet.Unidades || 0), 0);

						return {
							totalUnits: acc.totalUnits + pendingUnits,
							totalCantidad: acc.totalCantidad + cantidad
						};
					}, { totalUnits: 0, totalCantidad: 0 });

					// Guardar los totales junto con el nombre del checklist
					if (checklistTotals.totalUnits > 0) {
						inProcessSheets.push({
							name: checklist.Nombre || '',
							...checklistTotals
						});
					}
				});

				console.log('3. Total hojas procesadas:', sheets.length);
				console.log('4. Hojas en proceso:', inProcessSheets.length);
				

				setPendingSheets(inProcessSheets);
				setHasLoaded(true);

			} catch (error) {
				console.error('Error en el proceso:', error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();
	}, [selected.project?._ref?.id, hasLoaded]);

	// Reset hasLoaded cuando cambia el proyecto
	useEffect(() => {
		setHasLoaded(false);
	}, [selected.project?._ref?.id]);

	const goals = useMemo(
		() => project.checklists?.map((p) => p.MetaRevision ?? 0) ?? [],
		[project.checklists]
	);

	const realValues = Object.values(checklist.graphData ?? {});

	// Luego, en pendingGoals, calcular usando la suma de cantidades de los checklists
	const pendingGoals = useMemo(() => {
		if (!pendingSheets.length) return goals.map(() => 0);
		
		return project.checklists?.map(checklist => {
			// Buscar los totales para este checklist por nombre
			const checklistData = pendingSheets.find(sheet => 
				sheet.name === (checklist.Nombre || '')
			);
			if (!checklistData) return 0;
			
			return Math.round((checklistData.totalUnits / (checklist.Cantidad || 1)) * 100);
		}) || [];
		
	}, [pendingSheets, project.checklists, goals]);

	// Agregar un useEffect para monitorear los cambios en las dependencias
	useEffect(() => {
		console.log('Cambió goals:', goals);
		console.log('Cambió realValues:', realValues);
	}, [ goals, realValues]);

	const max = useMemo(() => {
		const values = [
			...realValues,
			...goals,
		];

		return Math.max(...values) * 1.15;
	}, [checklist.graphData, goals]);

	if (!checklist.graphData)
		return <Skeleton variant="rect" height={400} />;

	console.log('Pendientes finales:', pendingGoals);

	return (
		<Bar
			options={{
				legend: {
					display: true,
				},
				scales: {
					xAxes: [{
						stacked: false,
						categoryPercentage: 0.8,
						barPercentage: 0.9,
					}],
					yAxes: [
						{
							stacked: false,
							ticks: {
								beginAtZero: true,
								max,
							},
						},
					],
				},
				plugins: {
					labels: [
						{
							render: (col: any) => {
								// Solo mostrar valor si es mayor a 0
								if (col.value <= 0) return '';
								
						
								return `${col.value}%`;
							},
							position: "outside",
							precision: 2,
						},
					],
				},
			}}
			data={{
				labels: Object.keys(checklist.graphData),
				datasets: [
					{
						label: "Real",
						data: realValues,
						backgroundColor: "#00B050",
						stack: 'stack1'
					},
					{
						label: "Pendiente",
						data: pendingGoals,
						backgroundColor: "yellow",
						stack: 'stack1'
					},
					{
							label: "Meta",
							data: goals,
							backgroundColor: "#396CA2",
							stack: 'stack2'
					}
				],
			}}
		/>
	);
};
