import {
	Breadcrumbs as MuiBreadcrumbs,
	Button as MuiButton,
	Card as MuiCard,
	CircularProgress,
	Divider as MuiDivider,
	Fade,
	Grid,
	Typography as MuiTypography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { spacing } from "@material-ui/system";
import { useEffect, useCallback } from "react";
import { Trash2 as DeleteIcon } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { NewConfirmDialog } from "../../components/NewConfirmDialog";
import { useConfirmDialog } from "../../hooks/useConfirmDialog";
import {
	getBusiness,
	desactivarEmpresa,
} from "../../redux/actions/businessActions";
import { getAllBusinessProjects } from "../../redux/actions/projectsActions";
import { reloadDefaultTables } from "../../redux/actions/usersActions";
import { useStyles } from "../../theme/useStyles";
import { BusinessInfoTab } from "src/components/Business/Tabs/Info";
import { BusinessUsersTab } from "src/components/Business/Tabs/Users";
import { BusinessSubcontractTab } from "src/components/Business/Tabs/Subcontract";
import { LinkEmpresas } from "src/components/CheckPro/Links";
import { BusinessSectorTab } from "src/components/Business/Tabs/Sectors";
import { BusinessPrecinctsTab } from "src/components/Business/Tabs/Precints";
import { BusinessPrioritiesTab } from "src/components/Business/Tabs/Priorities";
import { BusinessCargosTab } from "src/components/Business/Tabs/Cargos";
import { useDispatch, useSelector } from "src/redux/store";
import { TabGroup } from "src/components/CheckPro/TabGroup";
import { BusinessNeoChecklistTab } from "../../components/Business/Tabs/NeoChecklistVars/NeoChecklists";
import { ParamTab } from "src/components/Business/Tabs/NeoChecklistVars/ParamTab";
import { BusinessChecklistsTab } from "src/components/Business/Tabs/Checklist";
import { useCheckproLinks } from "src/components/CheckPro/Links/hooks/useCheckproLinks";
import { getChecklist } from "src/redux/actions/checklistActions";

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Button = styled(MuiButton)(spacing);
const Typography = styled(MuiTypography)(spacing);

export const EditBusinessPage = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();

	const { idEmpresa } = useCheckproLinks();

	const {
		edit: { selectedBusiness, isLoading, updateLoading },
	} = useSelector((state) => state.businessReducer);
	const { tipoUsuario, empresaReference } =
		useSelector((state) => state.authReducer.user) ?? {};

	const { edit } = useSelector(
		(state) => state.usersReducer
	);

	useEffect(() => {
		dispatch(
			getBusiness(
				tipoUsuario === "Administrador"
					? idEmpresa
					: empresaReference?.id
			)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tipoUsuario, empresaReference?.id]);

	const {
		open,
		openDialog,
		dialogConfirmHandleConfirm,
		dialogConfirmHandleClose,
	} = useConfirmDialog({
		onConfirm: () => {
			if (!selectedBusiness?.id) return;
			dispatch(desactivarEmpresa(selectedBusiness.id));
			history.replace("/administrador/empresas");
		},
	});

	useEffect(() => {
		if (selectedBusiness) {
			dispatch(getAllBusinessProjects(selectedBusiness));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedBusiness]);

	useEffect(() => {
		console.log(
			"getting checklists page",
			selectedBusiness?.id
		);
		if (!selectedBusiness) return;
		dispatch(getChecklist(selectedBusiness.id));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Función para refrescar los datos del tab de usuarios
	const refreshUsersTab = useCallback(() => {
		console.log("refreshUsersTab");
		if (selectedBusiness) {
			// Recargar los proyectos de la empresa
			dispatch(getAllBusinessProjects(selectedBusiness));
			// Recargar los datos por defecto (incluye usuarios)
			dispatch(reloadDefaultTables());
		}
	}, [selectedBusiness, dispatch]);

	return (
		<>
			<Helmet title={selectedBusiness?.Nombre} />
			<Grid container justify="space-between">
				<Grid item>
					{isLoading ? (
						<Skeleton variant="text" />
					) : (
						<Typography
							variant="h3"
							gutterBottom
							display="inline"
						>
							{selectedBusiness?.Nombre}
						</Typography>
					)}

					<Breadcrumbs aria-label="Breadcrumb" mt={2}>
						<LinkEmpresas />
						<Typography>Configuración</Typography>
					</Breadcrumbs>
				</Grid>

				<Grid item>
					<Button
						variant="contained"
						color="primary"
						onClick={() => dispatch(reloadDefaultTables())}
						disabled={edit.isLoading}
					>
						Recargar Datos&nbsp;
						{edit.isLoading && (
							<CircularProgress size={20} />
						)}
					</Button>

					{tipoUsuario === "Administrador" && (
						<Button
							variant="contained"
							onClick={() => openDialog(selectedBusiness)}
							className={classes.deleteBtn}
							ml={2}
							disabled={updateLoading}
						>
							<DeleteIcon size={20} />
							&nbsp;Eliminar Empresa
						</Button>
					)}
				</Grid>
			</Grid>

			<Divider my={6} />
			{!isLoading && selectedBusiness ? (
				<Fade in={true}>
					<TabGroup
						display={{
							style: {
								borderTop: "1px solid #e8e8e8",
							},
						}}
						tabs={[
							"Empresa",
							"Revisiones",
							"Checklist Revisiones",
							"Checklists",
						]}
						hidden={[
							false,
							!selectedBusiness.Revisiones.Activado,
							!selectedBusiness.CheckList.Activado,
							!selectedBusiness.ChecklistsOT?.Activado,
						]}
					>
						{/* empresa */}
						<TabGroup
							variant="scrollable"
							tabs={["Información", "Usuarios", "Cargos"]}
							style={{
								marginBottom: 10,
							}}
						>
							<BusinessInfoTab />
							<BusinessUsersTab onRefresh={refreshUsersTab} />
							<BusinessCargosTab />
						</TabGroup>

						{/* revisiones */}
						<TabGroup
							style={{
								marginBottom: 10,
							}}
							variant="scrollable"
							tabs={[
								"Sectores",
								"Recintos",
								"Subcontratos",
								"Prioridades",
							]}
						>
							<BusinessSectorTab />
							<BusinessPrecinctsTab />
							<BusinessSubcontractTab />
							<BusinessPrioritiesTab />
						</TabGroup>

						{/* revisiones checklist */}
						<TabGroup
							style={{
								marginBottom: 10,
							}}
							variant="scrollable"
							tabs={[
								"Sectores",
								"Recintos",
								"Subcontratos",
								"Checklists",
							]}
						>
							<BusinessSectorTab />
							<BusinessPrecinctsTab />
							<BusinessSubcontractTab />
							<BusinessChecklistsTab />
						</TabGroup>

						<TabGroup
							style={{
								marginBottom: 10,
							}}
							variant="scrollable"
							tabs={[
								"Listas de chequeo",
								"Unidades",
								"Sectores",
								"Recintos",
								"Elementos",
								"Pasillos",
								"Otros",
							]}
						>
							<BusinessNeoChecklistTab />
							<ParamTab type="units" />
							<ParamTab type="sectors" />
							<ParamTab type="precincts" />
							<ParamTab type="elements" />
							<ParamTab type="corridors" />
							<ParamTab type="others" />
						</TabGroup>
					</TabGroup>
				</Fade>
			) : (
				<>
					<Card mb={4}>
						<Skeleton variant="rect" height={50} />
					</Card>
					<Card>
						<Skeleton variant="rect" height={170} />
					</Card>
				</>
			)}

			<NewConfirmDialog
				title={"¿Desea eliminar Empresa?"}
				onConfirmText={"Eliminar"}
				onConfirm={dialogConfirmHandleConfirm}
				open={open}
				onClose={dialogConfirmHandleClose}
			/>
		</>
	);
};
