import * as types from "../../constants";
import { Action } from "../../models/action";
import { SnackState } from "../../models/snack-state";
import {
	BaseProjectLegacy,
	AnyProject,
	RevisionChecklistProject,
} from "../../models/Proyecto";
import { ChecklistOT } from "src/models/OT/Checklist";
import { PlaceOT } from "src/models/OT/Place";
import { SheetOT } from "src/models/OT/Sheet";

interface ChecklistWithResponses {
	checklist: ChecklistOT;
	placesWithResponses: {
		place: PlaceOT;
		responses: SheetOT[];
	}[];
}

export interface IProjectsState {
	projects: AnyProject[];
	allListPdf: any[];
	userProjects: AnyProject[];
	checklists: RevisionChecklistProject[];
	projectChecklistState:
		| "Initial"
		| "Submiting"
		| "Success"
		| "Failure";
	isLoading: boolean;
	totalDocs: number;
	totalDocsProjectChecklist: number;
	lastDoc?: any;
	lastDocProjectChecklist?: any;
	error?: string;
	gettingUserProjectsIn:
		| "Initial"
		| "Submitting"
		| "Success"
		| "Failure";
	revisions: {
		selectedProject?: BaseProjectLegacy | null;
		isLoading?: boolean;
		error?: string;
		updateLoading?: boolean;
	};
	add: {
		isLoading: boolean;
		error?: string;
	};
	users: {
		isLoading: boolean;
		users: any[];
		error?: string;
	};
	businesses: {
		isLoading: boolean;
		businesses: any[];
		error?: string;
	};
	hideDeleted: boolean;
	restore: {
		state: string;
		error?: any;
	};
	allProjects: AnyProject[];
	allProjectsState:
		| "initial"
		| "submitting"
		| "success"
		| "failure";
	allProjectsError?: any;
	checklistsOT: {
		data: ChecklistWithResponses[];
		loading: boolean;
		error?: string;
	};
}

const initialState = {
	projects: [],
	allListPdf: [],
	gettingUserProjectsIn: "Initial",
	userProjects: [],
	checklists: [],
	projectChecklistState: "Initial",
	isLoading: false,
	totalDocs: 0,
	totalDocsProjectChecklist: 0,
	snackState: SnackState.INITIAL,
	snackText: "",
	snackOpen: false,
	revisions: {
		selectedProject: null,
		isLoading: false,
		updateLoading: false,
	},
	users: {
		isLoading: false,
		users: [],
	},
	businesses: {
		isLoading: false,
		businesses: [],
	},
	add: {
		isLoading: false,
	},
	hideDeleted: true,
	restore: {
		state: "Initial",
	},
	allProjects: [],
	allProjectsState: "initial",
	checklistsOT: {
		data: [],
		loading: false,
		error: undefined
	}
} as IProjectsState;

export const projectsReducer = (
	state = initialState,
	action: Action
): IProjectsState => {
	switch (action.type) {
		case types.PROJECTS_GET_DOCS:
			return {
				...state,
				projects: action.payload,
			};
		case types.PROJECTS_USER_GET_DOCS:
			return {
				...state,
				userProjects: action.payload,
			};
		case types.PROJECTS_SELECTED_DOC:
			return {
				...state,
				revisions: {
					selectedProject: action.payload,
				},
			};
		case types.PROJECT_GET_ONE_SUCCESS:
			return {
				...state,
				revisions: {
					selectedProject: action.payload,
				},
			};
		case types.PROJECTS_UPDATE_SELECTED:
			return {
				...state,
				revisions: {
					...state.revisions,
					selectedProject: {
						...state.revisions.selectedProject,
						...action.payload,
					},
				},
			};
		case types.PROJECTS_ADD_DOC_LOADING:
			return {
				...state,
				add: {
					...state.add,
					isLoading: action.payload,
				},
			};
		case types.PROJECTS_UPDATE_DOC:
			return {
				...state,
				projects: state.projects.map((x) =>
					x.id === action.payload.id
						? { ...x, ...action.payload }
						: x
				),
			};
		case types.PROJECTS_REMOVE_DOC:
			return {
				...state,
				projects: state.projects.filter(
					(x) => x.id !== action.payload.id
				),
			};
		case types.PROJECTS_ADD_DOCS:
			return {
				...state,
				projects: [...state.projects, ...action.payload],
			};
		case types.PROJECTS_SET_LAST_DOC:
			return {
				...state,
				lastDoc: action.payload,
			};
		case types.PROJECTS_SET_TOTAL_DOCS:
			return {
				...state,
				totalDocs: action.payload,
			};
		case types.PROJECTS_LOADING:
			return {
				...state,
				isLoading: action.payload,
			};
		case types.PROJECTS_FAILURE:
			return {
				...state,
				error: action.payload,
			};
		//revisions
		case types.PROJECTS_EDIT_LOADING:
			return {
				...state,
				revisions: {
					isLoading: action.payload,
				},
			};
		case types.PROJECTS_UPDATE_LOADING:
			return {
				...state,
				revisions: {
					...state.revisions,
					updateLoading: action.payload,
				},
			};
		//Users
		case types.PROJECTS_GET_USERS:
			return {
				...state,
				users: {
					...state.users,
					users: action.payload,
				},
			};
		case types.PROJECTS_USERS_LOADING:
			return {
				...state,
				users: {
					...state.users,
					isLoading: action.payload,
				},
			};

		//Business
		case types.PROJECTS_GET_BUSINESS:
			return {
				...state,
				businesses: {
					...state.businesses,
					businesses: action.payload,
				},
			};
		case types.PROJECTS_BUSINESS_LOADING:
			return {
				...state,
				businesses: {
					...state.businesses,
					isLoading: action.payload,
				},
			};

		//Revisions
		case types.PROJECTS_REVISIONS_FAILURE:
			return {
				...state,
				revisions: {
					...state.revisions,
					error: action.payload,
				},
			};
		case types.PROJECTS_REVISIONS_lOADING:
			return {
				...state,
				revisions: {
					...state.revisions,
					isLoading: action.payload,
				},
			};
		case types.PROJECTS_HIDE_CHANGE:
			return {
				...state,
				hideDeleted: action.payload,
			};
		case types.PROJECTS_RESTORE_SUBMITTING:
			return {
				...state,
				restore: {
					state: "Submitting",
					error: undefined,
				},
			};
		case types.PROJECTS_RESTORE_SUCCESS:
			return {
				...state,
				restore: {
					state: "Success",
				},
				projects: state.projects.filter(
					(x) => x.id !== action.payload.id
				),
			};
		case types.PROJECTS_RESTORE_FAILURE:
			return {
				...state,
				restore: {
					state: "Failure",
					error: action.payload,
				},
			};

		// Set state getting User in *** UTIL ***
		case types.PROJECT_USER_IN_SET_STATE:
			return {
				...state,
				gettingUserProjectsIn: action.payload,
			};
		// Proyectos para listado en usuarios
		case types.PROJECTS_GET_ALL_BUSINESS_SUBMITTING:
			return {
				...state,
				allProjectsState: "submitting",
				allProjectsError: undefined,
			};
		case types.PROJECTS_GET_ALL_BUSINESS_SUCCESS:
			return {
				...state,
				allProjectsState: "success",
				allProjects: action.payload,
			};
		case types.PROJECTS_GET_ALL_BUSINESS_FAILURE:
			return {
				...state,
				allProjectsState: "failure",
				allProjectsError: action.payload,
			};
		// project of type Checklist (new module)

		case types.CHECKLISTS_GET_SUBMITTING:
			return {
				...state,
				projectChecklistState: "Submiting",
			};
		case types.CHECKLISTS_GET_SUCCESS:
			return {
				...state,
				projectChecklistState: "Success",
				checklists: action.payload.projectsChecklist,
				totalDocsProjectChecklist:
					action.payload.totalDocsProjectChecklist,
				lastDocProjectChecklist:
					action.payload.lastDocProjectChecklist,
			};
		case types.CHECKLISTS_GET_FAILURE:
			return {
				...state,
				projectChecklistState: "Failure",
			};
		// Agregar nuevos casos para ChecklistsOT
		case types.CHECKLISTS_OT_GET_SUBMITTING:
			return {
				...state,
				checklistsOT: {
					...state.checklistsOT,
					loading: true,
					error: undefined
				}
			};

		case types.CHECKLISTS_OT_GET_SUCCESS:
			return {
				...state,
				checklistsOT: {
					data: action.payload,
					loading: false,
					error: undefined
				}
			};

		case types.CHECKLISTS_OT_GET_FAILURE:
			return {
				...state,
				checklistsOT: {
					...state.checklistsOT,
					loading: false,
					error: action.payload
				}
			};

		default:
			return state;
	}
};
