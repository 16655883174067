import {
	Box,
	Breadcrumbs,
	Button,
	Divider,
	Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { LinkEmpresas } from "src/components/CheckPro/Links";
import { LinkNeoChecklists } from "src/components/CheckPro/Links/NeoChecklists";
import { LinkNeoPlaces } from "src/components/CheckPro/Links/NeoPlaces";
import { LinkProjectsOT } from "src/components/CheckPro/Links/NeoProjects";
import { SheetAccordionOT } from "src/components/ProjectsOT/PlacesOT/ResponsesOT/Accordions/SheetOT";
import { CreateSheetDialog } from "src/components/ProjectsOT/PlacesOT/ResponsesOT/Dialogs/CreateSheet";
import { useBusiness } from "src/hooks/redux/useBusiness";
import { useProjectsOT } from "src/hooks/redux/useProjectsOT";
export const PlaceAnswersPageOT = () => {
	const { selectedBusiness } = useBusiness();
	const { selected, checklist, get } =
		useProjectsOT?.() ?? {};
	const [dialog, setDialog] = useState(0);
	const [refreshTrigger, setRefreshTrigger] = useState(0);

	const sheets = checklist.responses;

	useEffect(() => {
		if (!selected.assignedPlace) return;
		get.place.sheets();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected.assignedPlace, refreshTrigger]);

	useEffect(() => {
		if (!sheets) return;
		get.sheet.users();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sheets]);



	const maxedOut = useMemo(
		() =>
			(checklist.responses?.reduce(
				(acc, response) => acc + (response.Unidades ?? 0),
				0
			) ?? 0) === (selected.assignedPlace?.Cantidad ?? 0),
		[checklist.responses, selected.assignedPlace?.Cantidad]
	);

	return (
		<>
			<Helmet title="Respuestas" />

			<Breadcrumbs style={{ fontSize: 20 }}>
				<Typography variant="h3" style={{ color: "black" }}>
					{selectedBusiness?.Nombre ?? "Empresa"}
				</Typography>

				<Typography variant="h3" style={{ color: "black" }}>
					{selected.project?.Nombre ?? "Proyecto"}
				</Typography>

				<Typography variant="h3" style={{ color: "black" }}>
					{selected.checklist?.Nombre ?? "Lista de chequeo"}
				</Typography>

				<Typography variant="h3" style={{ color: "black" }}>
					{selected.assignedPlace?.Nombre ?? "Espacio"}
				</Typography>
			</Breadcrumbs>

			<Box display="flex" flexDirection="row">
				<Breadcrumbs style={{ flexGrow: 1 }}>
					<LinkEmpresas />
					<LinkProjectsOT />
					<LinkNeoChecklists />
					<LinkNeoPlaces />
					<Typography>Respuestas</Typography>
				</Breadcrumbs>
			</Box>

			<Divider
				style={{ marginTop: 10, marginBottom: 10 }}
			/>

			<Button
				color="primary"
				variant="contained"
				size="large"
				style={{ marginBottom: 10 }}
				onClick={() => setDialog(1)}
				fullWidth
				startIcon={<Add />}
				disabled={maxedOut}
			>
				{maxedOut
					? "Se han utilizado todas las unidades."
					: "Agregar hoja de respuestas"}
			</Button>

			{sheets?.map((sheet) => (
				<SheetAccordionOT
					sheet={sheet}
					key={`sheet_${sheet._position}`}
				/>
			))}

			{!sheets && (
				<>
					<Skeleton variant="rect" height={200} />
					<Skeleton variant="rect" height={200} />
					<Skeleton variant="rect" height={200} />
				</>
			)}

			<CreateSheetDialog
				open={dialog === 1}
				onClose={() => {
					setDialog(0);
					setRefreshTrigger(prev => prev + 1);
				}}
			/>
		</>
	);
};
