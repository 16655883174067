import {
	SimpleCrud,
	SimpleCrudProps,
} from "src/components/SimpleCrud";
import { useBusiness } from "src/hooks/redux/useBusiness";
import {
	addBusinessPriority,
	removeBusinessPriority,
	updateBusinessPriority,
} from "src/redux/actions/businessActions";
import { useDispatch } from "src/redux/store";

export const BusinessPrioritiesTab = () => {
	const dispatch = useDispatch();
	const { selectedBusiness, loading } = useBusiness();

	const handleSubmit: SimpleCrudProps["onCreateOrUpdate"] =
		(values, action, selected) => {
			if (action === "New")
				dispatch(addBusinessPriority(values));
			else if (action === "Edit")
				dispatch(updateBusinessPriority(values, selected));
		};

	const handleDelete: SimpleCrudProps["onDelete"] = (
		selected
	) => {
		dispatch(removeBusinessPriority(selected));
	};

	return (
		<SimpleCrud
			key={"Prioridades"}
			array={[...new Set(selectedBusiness?.Prioridades)]}
			isLoading={loading}
			name={"Prioridad"}
			onCreateOrUpdate={handleSubmit}
			onDelete={handleDelete}
		/>
	);
};
